/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat:500,700&display=swap');
body {
  margin: 0 auto;
  max-width: 3000px;
  width:100%;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
.active a {
  color: white;
  text-decoration: none;
  cursor: pointer;
  outline: none !important;
  background: none;
}
a:hover {
  -webkit-box-pack: unset;
}
a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
::-webkit-scrollbar {
  display: none;
}
.bar-class {
  color: white;
  font-family: 'Montserrat';
  font-size: rem-calc(25px);
  width:100%;
}
.gif-proccess{
    width: 72px;
    display: block;
    position: absolute;
    top: -10px;
    right: 80px;
}
.grecaptcha-badge { 
  visibility: hidden;
}
/* change design for angular material  */
.mat-form-field.mat-focused .mat-form-field-label {
  color: black !important;
}
.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background-color: black !important;
}
.mat-form-field-infix {
  font-size: 12px;
}
.mat-form-field-required-marker {
  color: red;
}

input[type="radio"]:checked:before {
  content: "";
  display: block;
  position: relative;
  top: 1.8px;
  left: 1.8px;
  width: 8.5px;
  height: 8.5px;
  border-radius: 50%;
  background: red;
}
.mat-select-panel:not([class*="mat-elevation-z"]) {
  margin-top: 30px;
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: red;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}
.mat-form-field-hide-placeholder .mat-select-placeholder {
  color: black;
  -webkit-text-fill-color: black;
  transition: none;
  display: block;
}
